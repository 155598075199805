<template>
  <b-navbar class="static-top mb-3" type="dark" toggleable="sm" >
    <b-navbar-brand to="/" class="m-0 p-0">
      my <img
        src="@/assets/img/peloton-logo/red.svg"
        class="peloton-logo m-1" alt="Peloton"/> analytics
    </b-navbar-brand>
    <b-navbar-toggle target="navbar-toggle-collapse" h-align="right"></b-navbar-toggle>
    <b-collapse id="navbar-toggle-collapse" is-nav>
      <b-navbar-nav class="site-nav ml-auto centered">
        <b-nav-item-dropdown left :text="currentPage">
          <b-dropdown-item :to="'dashboard'" class="text-center">Dashboard</b-dropdown-item>
          <b-dropdown-item :to="'cycling'" v-if="pelotonUsername" class="text-center">Cycling</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
      <b-navbar-nav class="ml-auto">
        <b-nav-item-dropdown right class="d-block">
          <template v-slot:button-content>
            <b-avatar variant="primary" :src="pelotonProfileImage" class="mr-3 d-none d-md-inline-block"></b-avatar>{{ pelotonUsername }}
          </template>
          <b-dropdown-item v-b-modal.updateData v-if="pelotonUsername" class="text-center">Update Data</b-dropdown-item>
          <b-dropdown-item @click="logout" class="text-center">Logout</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<style scoped lang="scss">
  @import '../styles/TheNavbar.scss';
</style>

<script>
export default {
  data() {
    return {
      expanded: false
    }
  },
  computed: {
    pelotonUsername() {
      return this.$store.state.User.user.pelotonUsername
    },
    pelotonProfileImage() {
      return this.$store.state.User.user.pelotonProfileImage
    },
    currentPage() {
      return this.$route.name
    },
  },
  methods: {
    logout() {
      this.$store.dispatch('User/logout')
        .then(() => {
          this.$router.push('/')
        })
    },
  },
  name: 'TheNavbar',
}
</script>
