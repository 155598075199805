import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';

Vue.use(Vuex);

export default {
  actions: {
    authenticate({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit('auth_request');
        axios.get(`${process.env.VUE_APP_API_HOST}/users/status`, { data: user })
          .then((resp) => {
            commit('auth_success');
            resolve(resp);
          })
          .catch((err) => {
            console.log('failed status check  - error');
            commit('auth_error');
            reject(err.response.data.error);
          });
      });
    },
    linkAccounts({ commit }, pelotonData) {
      return new Promise((resolve, reject) => {
        commit('auth_request');
        axios.post(`${process.env.VUE_APP_API_HOST}/peloton/link-accounts`, pelotonData)
          .then((resp) => {
            commit('login', resp.data);
            resolve(resp);
          })
          .catch((err) => {
            commit('auth_error');
            reject(err.response.data.error);
          });
      });
    },
    login({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit('auth_request');
        axios.post(`${process.env.VUE_APP_API_HOST}/auth/login`, user)
          .then((resp) => {
            commit('login', resp.data);
            resolve(resp);
          })
          .catch((err) => {
            commit('auth_error');
            reject(err.response.data.error);
          });
      });
    },
    logout({ dispatch }) { 
      dispatch("reset", null, { root: true });
    },
    signup({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit('auth_request');
        axios.post(`${process.env.VUE_APP_API_HOST}/auth/signup`, user)
          .then((resp) => {
            commit('auth_success');
            commit('login', resp.data);
            resolve(resp);
          })
          .catch((err) => {
            commit('auth_error', err);
            reject(err.response.data.error);
          });
      });
    },
  },
  getters: {
    isLoggedIn: state => !!state.user.auth,
    authStatus: state => state.status,
    user: state => state.user,
  },
  mutations: {
    auth_request(state) {
      state.status = 'loading';
    },
    auth_success(state) {
      state.status = 'success';
    },
    auth_error(state) {
      state.status = 'error';
      state.user = {};
    },
    login(state, data) {
      state.status = 'success';
      state.user = {
        ...data
      };
    },
    reset(state) {
      state.status = '';
      state.user = {};
    },
  },
  namespaced: true,
  state: {
    status: '',
    user: {},
  },
};
