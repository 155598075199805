<template>
  <b-card>
    <b-container fluid>
      <b-row>
        <h4>{{ title }}</h4>
      </b-row>
      <LoadingSpinner :loading="loading" />
      <b-row align-h="between" v-if="!loading">
        <b-col class="text-center text-secondary stat-value">
          <b-badge class="badge-secondary badge-outlined" v-if="metric !== 'duration'">
            {{ numericDisplay(value) }} <span v-if="units">{{ units }}</span>
          </b-badge>
          <b-badge class="badge-secondary badge-outlined" v-if="metric === 'duration'">
            {{ timeDisplay(value) }} <span v-if="units">{{ units }}</span>
          </b-badge>
        </b-col>
      </b-row>
    </b-container>
  </b-card>
</template>

<script>
import LoadingSpinner from './LoadingSpinner'

export default {
  components: {
    LoadingSpinner,
  },
  methods: {
    timeDisplay(value) {
      const hours = Math.floor(value / 60 / 60)
      const minutes = ((value / 60) % 60)
      return `${hours}hr ${minutes}min`
    },
    numericDisplay(value) {
      return value.toLocaleString()
    },
  },
  name: 'MetricDisplay',
  props: {
    title: String,
    metric: String,
    value: Number,
    units: String,
    loading: Boolean
  },
}
</script>
