<template>
  <b-container>
    <footer class="footer text-center mt-3">
      <div>
        <span>
          &copy; {{ year }} Markham F Rollins IV<br />
          Digital Assets Owned By <a href="https://www.onepeloton.com/"
                                     target="_blank"
                                     class="text-secondary">Peloton Interactive, Inc.</a>
        </span>
      </div>
    </footer>
  </b-container>
</template>

<script>
import moment from 'moment'

export default {
  computed: {
    year() {
      return moment().format('YYYY')
    },
  },
  name: 'TheFooter',
}
</script>
