import Vue from 'vue';
import Vuex from 'vuex';
import createLogger from 'vuex/dist/logger';
import createPersistedState from 'vuex-persistedstate';
import _get from 'lodash/get';

// import the auto exporter
import modules from './modules';

Vue.use(Vuex);
const debug = process.env.NODE_ENV !== 'production';
const persistedStateSettings = {
  key: 'vuex',
  reducer(val) {
    if (!_get(val, 'User.user.token')) { // return empty state when user logged out
      return {};
    }
    return val;
  },
};

export default new Vuex.Store({
  actions: {
    reset({ commit }) {
      // resets state of all the modules
      Object.keys(modules).forEach((moduleName) => {
        commit(`${moduleName}/reset`);
      });
    },
  },
  modules,
  strict: debug,
  plugins: debug ? [
    createLogger(),
    createPersistedState(persistedStateSettings),
  ] : [createPersistedState(persistedStateSettings)],
});
