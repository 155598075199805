import querystring from 'querystring';
import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import moment from 'moment';
import regression from 'regression';

Vue.use(Vuex);

export default {
  actions: {
    loadChartData({ commit }, params) {
      const clone = Object.assign({}, params);
      const { options, filters } = clone;

      if (filters.timeframe !== '') {
        const [value, unit] = filters.timeframe.split('-');
        filters.from = moment().subtract(value, unit).valueOf();
      } else {
        filters.from = 0
      }
      filters.to = moment().valueOf();
      // Remove from query string
      delete filters.timeframe;

      // @TODO: Don't make axios request if in state
      return new Promise((resolve, reject) => {
        commit('charts_request');
        axios
          .get(`${process.env.VUE_APP_API_HOST}/workouts?fitnessDiscipline=cycling&sortOrder=ASC&${querystring.stringify(filters)}`)
          .then((response) => {
            const charts = {};
            const workouts = response.data.workouts;

            // Set up each graph
            const metricsData = {};
            // eslint-disable-next-line guard-for-in,no-restricted-syntax
            workouts.forEach((workout, index) => {
              workout.performance.metrics.forEach((metric) => {
                if (!metricsData[metric.slug]) {
                  metricsData[metric.slug] = {
                    name: metric.displayName,
                    data: [],
                    id: 'base',
                  };
                }
                metricsData[metric.slug].data.push({
                  x: index,
                  y: metric.averageValue,
                  name: '',
                  className: workout.data.ride.title,
                  unit: metric.displayUnit,
                });
              });
            });

            // eslint-disable-next-line guard-for-in,no-restricted-syntax
            for (const metricSlug in metricsData) {
              charts[metricSlug] = {
                ...options,
                ...{
                  title: metricsData[metricSlug].name,
                  series: [
                    metricsData[metricSlug],
                    {
                      type: 'line',
                      linkedTo: 'base',
                      data: [],
                      marker: {
                        enabled: false,
                      },
                      states: {
                        hover: {
                          lineWidth: 0,
                        },
                      },
                      enableMouseTracking: false,
                      regression: true,
                    },
                  ],
                },
              };

              // If only 1 point on the line, show it as a dot
              if (metricsData[metricSlug].data.length === 1) {
                charts[metricSlug].plotOptions.series.marker.enabled = true;
              } else {
                charts[metricSlug].plotOptions.series.marker.enabled = false;
              }

              // Only put in regression if over minimum rides
              if (metricsData[metricSlug].data.length > 5) {
                const regressionPoints = regression.linear(
                  metricsData[metricSlug].data.map(point => [point.x, point.y]),
                );

                // Set up classname for positive/negative/neutral
                const regressionData = [
                  regressionPoints.points.shift(),
                  regressionPoints.points.pop(),
                ];

                // Some metrics have inverse positive
                let difference = regressionData[0][1] - regressionData[1][1];
                switch (metricSlug) {
                  case 'heart_rate':
                    difference *= -1;
                    break;
                  default:
                    break;
                }

                if (difference > 3) {
                  charts[metricSlug].chart = {
                    ...charts[metricSlug].chart,
                    ...{ className: 'trend-down' },
                  };
                } else if (difference < -3) {
                  charts[metricSlug].chart = {
                    ...charts[metricSlug].chart,
                    ...{ className: 'trend-up' },
                  };
                } else {
                  charts[metricSlug].chart = {
                    ...charts[metricSlug].chart,
                    ...{ className: 'neutral' },
                  };
                }

                // Calculate the regression line
                charts[metricSlug].series[1].data = regressionData;
              }
            }

            commit('charts_loaded');
            resolve(charts);
          })
          .catch((err) => {
            commit('charts_error', err);
            reject(err);
          });
      });
    },
    loadMetricFilters({ commit }) {
      return new Promise((resolve, reject) => {
        commit('metric_filters_request');
        // eslint-disable-next-line max-len
        axios
          .get(`${process.env.VUE_APP_API_HOST}/peloton/metric-filters/cycling`)
          .then((response) => {
            const metricFilters = response.data;
            commit('metric_filters_loaded', metricFilters);
            resolve(metricFilters);
          })
          .catch((err) => {
            commit('metric_filters_error', err);
            reject(err);
          });
      });
    },
    loadStatsData({ commit }) {
      return new Promise((resolve, reject) => {
        // eslint-disable-next-line max-len
        axios
          .get(`${process.env.VUE_APP_API_HOST}/stats/summary/cycling?from=0`)
          .then((response) => {
            const stats = response.data;
            commit('stats_loaded', stats);
            resolve(stats);
          })
          .catch((err) => {
            commit('stats_error', err);
            reject(err);
          });
      });
    },
  },
  getters: {
    stats: state => state.stats,
  },
  mutations: {
    charts_request(state) {
      state.status = 'loading';
    },
    charts_error(state) {
      state.status = 'error';
    },
    charts_loaded(state) {
      state.status = 'success';
    },
    metric_filters_request(state) {
      state.status = 'loading';
    },
    metric_filters_error(state) {
      state.status = 'error';
    },
    metric_filters_loaded(state, metricFilters) {
      state.metricFilters = metricFilters;
      state.status = 'success';
    },
    reset(state) {
      state.status = '';
    },
    stats_loaded(state, stats) {
      state.stats = stats;
    },
  },
  namespaced: true,
  state: {
    metricFilters: [],
  },
};
