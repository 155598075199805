<template>
  <div>
    <b-table
      sticky-header
      :items="instructors"
      :fields="fields"
      borderless
      hover
      head-variant="dark"
      class="instructor-summary w-100 mb-0">
      <template v-slot:cell(instructor)="data">
        <span class="text-nowrap">
          <b-avatar
            :src="data.value.image"
            size="sm"
            class="mr-2"></b-avatar>
          {{ data.value.name }}
        </span>
      </template>
      <template v-slot:head()="data">
        <h4 class="mb-0">{{ data.label }}</h4>
      </template>
    </b-table>
    <b-row align-h="center">
      <b-icon icon="chevron-bar-down"></b-icon>
    </b-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fields: [
        {
          key: 'instructor',
          sortable: true,
          stickyColumn: true
        },
        {
          key: 'workouts',
          label: 'Total',
          sortable: true,
          tdClass: 'text-center text-secondary font-weight-bold',
          thClass: 'text-center',
        },
        {
          key: 'avgDifficulty',
          label: 'Avg. Difficulty',
          sortable: true,
          tdClass: 'text-center text-secondary font-weight-bold',
          thClass: 'text-center',
        },
        {
          key: 'avgOutput',
          label: 'Avg. Output',
          sortable: true,
          tdClass: 'text-center text-secondary font-weight-bold',
          thClass: 'text-center',
        },
      ],
      instructors: [],
    }
  },
  methods: {
    numericDisplay(value, digits) {
      return value.toFixed(digits).toLocaleString()
    },
  },
  mounted() {
    // Loop over the instructors and set up the table data
    this.data.forEach((instructorWorkoutData) => {
      this.instructors.push({
        instructor: {
          name: instructorWorkoutData.instructor.name,
          image: instructorWorkoutData.instructor.imageUrl,
        },
        workouts: instructorWorkoutData.totalWorkouts,
        avgDifficulty: this.numericDisplay(instructorWorkoutData.avgDifficulty, 2),
        avgOutput: this.numericDisplay(instructorWorkoutData.avgOutput, 0),
      })
    })
  },
  props: {
    data: Array,
  },
  name: 'InstructorStats',
}
</script>
