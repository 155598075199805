<template>
  <b-container class="dashboard">
    <b-row align-h="center" class="panel">
      <b-col cols="12" lg="8">
        <b-row v-if="!pelotonUsername">
          <b-col cols="12" class="panel">
            <b-card>
              <b-row align-h="center">
                <h4>You have not yet linked your Peloton account</h4>
              </b-row>
              <b-row align-h="center">
                <b-col cols="4">
                  <b-alert v-if="linkAccountsError" show variant="danger" class="mb-0">{{ linkAccountsError }}</b-alert>
                  <b-form @submit.prevent="linkAccounts" novalidate>
                    <b-form-group
                      label="Peloton Username:"
                      label-for="pelotonEmail"
                    >
                      <b-form-input
                        id="pelotonEmail"
                        type="text"
                        v-model="pelotonEmail"
                        :class="{ 'is-invalid': submitted && $v.pelotonEmail.$error }"
                        required autofocus />
                    </b-form-group>
                    <b-form-group
                      label="Peloton Password:"
                      label-for="pelotonPassword"
                    >
                      <b-form-input
                        id="pelotonPassword"
                        type="password"
                        v-model="pelotonPassword"
                        :class="{ 'is-invalid': submitted && $v.pelotonPassword.$error }"
                        required autofocus />
                    </b-form-group>
                    <b-button type="submit" class="text-uppercase">Link Accounts</b-button>
                  </b-form>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
        <b-row v-if="pelotonUsername">
          <b-col cols="12" md="4" class="panel">
            <StatDisplay
              :loading="accountStatsLoading"
              title="Workouts"
              :metric="accountStats.workouts ? accountStats.workouts.slug : ''"
              :value="accountStats.workouts ? accountStats.workouts.value : null"
              :units="accountStats.workouts ? accountStats.workouts.displayUnit : ''" />
          </b-col>
          <b-col cols="12" md="4" class="panel">
            <StatDisplay
              :loading="accountStatsLoading"
              title="Calories"
              :metric="accountStats.calories ? accountStats.calories.slug : ''"
              :value="accountStats.calories ? accountStats.calories.value : null"
              :units="accountStats.calories ? accountStats.calories.displayUnit : ''" />
          </b-col>
          <b-col cols="12" md="4" class="panel">
            <StatDisplay
              :loading="accountStatsLoading"
              title="Time"
              :metric="accountStats.duration ? accountStats.duration.slug : ''"
              :value="accountStats.duration ? accountStats.duration.value : null"
              :units="accountStats.duration ? accountStats.duration.displayUnit : ''" />
          </b-col>
        </b-row>
        <b-row v-if="pelotonUsername">
          <b-col cols="12" class="panel">
            <WorkoutsList title="Recent Workouts" :fixedHeight=true :from="workoutsFrom" :to="workoutsTo"/>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" lg="4" class="panel" v-if="pelotonUsername">
        <b-row align-h="center" class="panel">
          <b-col lg="12">
            <WorkoutsCalendar />
          </b-col>
        </b-row>
        <b-row align-h="center" class="panel">
          <b-col>
            <WorkoutsSummary />
          </b-col>
        </b-row>
        <b-row align-h="center" class="panel">
          <b-col lg="12">
            <AchievementsList />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<style scoped lang="scss">
  @import '../styles/TheDashboard.scss';
</style>

<script>
import axios from 'axios'
import moment from 'moment'
import { required } from 'vuelidate/lib/validators'
import AchievementsList from './modules/AchievementsList'
import StatDisplay from './modules/StatDisplay'
import WorkoutsCalendar from './modules/WorkoutsCalendar'
import WorkoutsSummary from './modules/WorkoutsSummary'
import WorkoutsList from './modules/WorkoutsList'
import { pickBy } from 'lodash'

export default {
  components: {
    AchievementsList,
    StatDisplay,
    WorkoutsCalendar,
    WorkoutsList,
    WorkoutsSummary,
  },
  computed: {
    pelotonUsername() {
      return this.$store.state.User.user.pelotonUsername
    },
    workoutsFrom() {
      return moment().subtract(30, 'days').valueOf()
    },
    workoutsTo() {
      return moment().valueOf()
    },
  },
  data() {
    return {
      accountStats: {},
      accountStatsLoading: true,
      errored: false,
      pelotonEmail: '',
      pelotonPassword: '',
      submitted: false,
      linkAccountsError: '',
    }
  },
  methods: {
    linkAccounts() {
      this.submitted = true

      // stop here if form is invalid
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      const data = {
        pelotonEmail: this.pelotonEmail,
        pelotonPassword: this.pelotonPassword,
      }
      this.linkAccountsError = ''
      this.$store.dispatch('User/linkAccounts', data)
        .then(() => this.$router.go(this.$router.currentRoute))
        .catch((err) => {
          this.linkAccountsError = err.message
        })
    },
  },
  mounted() {
    if (this.pelotonUsername) {
      // eslint-disable-next-line max-len
      axios
        .get(`${process.env.VUE_APP_API_HOST}/stats/summary?from=0`)
        .then((response) => {
          this.accountStatsLoading = false
          this.accountStats = pickBy(response.data, (stat) => stat.universal)
        })
        .catch((err) => {
          console.log(err)
          this.errored = true
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  name: 'TheDashboard',
  validations: {
    pelotonEmail: {
      required,
    },
    pelotonPassword: {
      required,
    },
  },
}
</script>
