<template>
  <b-row align-h="center" v-if="loading">
    <FlowerSpinner
      :animation-duration="1200"
      :size="55"
      color="#ff1d5e"
    />
  </b-row>
</template>

<script>
import { FlowerSpinner } from 'epic-spinners'

export default {
  components: {
    FlowerSpinner,
  },
  data() {
    return {
      records: [],
    }
  },
  props: {
    loading: Boolean,
  },
  name: 'LoadingSpinner',
}
</script>
