import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';

import Vue from 'vue';
import Vuelidate from 'vuelidate';
import VueGtag from "vue-gtag";
import axios from 'axios';
import HighchartsVue from 'highcharts-vue';
import VCalendar from 'v-calendar';
import App from './App';
import router from './router';
import store from './store';


Vue.use(VueGtag, {
  config: { id: "G-Q7G7XK4SEJ" }
});

// Add a request interceptor
axios.interceptors.request.use(function (config) {
    const token = store.state.User.user.token;
    config.headers["x-access-token"] = token;

    return config;
});

// Set axios to be in vue
Vue.prototype.$http = axios;

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(Vuelidate);
Vue.use(VCalendar, {
  componentPrefix: 'vc',
});
Vue.use(HighchartsVue);

Vue.config.productionTip = false;

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  components: { App },
  template: '<App/>',
  render: (h) => h(App),
  store,
});
