<template>
  <div class="app" :class="isLoggedIn ? 'logged-in' : ''">
    <TheNavbar v-if="isLoggedIn"/>
    <router-view/>
    <b-modal size="sm" centered hide-header hide-footer v-if="isLoggedIn" id="updateData">
      <LoadingSpinner :loading="updatingData" />
      <b-form @submit.prevent="updateData" novalidate v-if="!updatingData">
        <b-alert v-if="updateDataError" show variant="danger" class="mb-0">{{ updateDataError }}</b-alert>
        <b-form-group
          label="Peloton Password:"
          label-for="pelotonPassword"
        >
          <b-form-input
            id="pelotonPassword"
            type="password"
            v-model="pelotonPassword"
            :class="{ 'is-invalid': submitted && $v.pelotonPassword.$error }"
            required autofocus />
        </b-form-group>
        <b-button type="submit">Update Data</b-button>
      </b-form>
    </b-modal>
    <TheFooter v-if="isLoggedIn"/>
  </div>
</template>

<script>
import axios from 'axios'
import { required } from 'vuelidate/lib/validators'
import LoadingSpinner from './components/modules/LoadingSpinner'
import TheFooter from './components/TheFooter'
import TheNavbar from './components/TheNavbar'

export default {
  computed: {
    isLoggedIn() {
      return this.$store.getters['User/isLoggedIn']
    },
  },
  components: {
    LoadingSpinner,
    TheFooter,
    TheNavbar,
  },
  created() {
    this.$http.interceptors.response.use(undefined, err => new Promise(() => {
      // eslint-disable-next-line no-underscore-dangle
      if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
        this.$store.dispatch('User/logout')
      }
      throw err
    }))
  },
  data() {
    return {
      pelotonPassword: '',
      updateDataError: '',
      updatingData: false,
      submitted: false,
    }
  },
  methods: {
    updateData() {
      this.submitted = true

      // stop here if form is invalid
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      this.updatingData = true

      const data = {
        pelotonPassword: this.pelotonPassword,
      }
      this.updateDataError = ''
      axios.post(`${process.env.VUE_APP_API_HOST}/peloton/sync-workouts`, data)
        .then(() => {
          this.$router.go(this.$router.currentRoute)
        })
        .catch((err) => {
          this.updatingData = false
          this.updateDataError = err.response.data.error.message
        })
    },
  },
  name: 'App',
  validations: {
    pelotonPassword: {
      required,
    },
  },
}
</script>

<style lang="scss">
@import 'styles/App.scss';
</style>
