<template>
  <b-container class="dashboard">
    <b-row align-h="center">
      <b-col
        cols="12">
        <b-row>
          <b-col
            cols="12"
            lg="6"
            class="panel">
            <b-card class="h-100">
              <b-container fluid>
                <b-row align-h="center" class="text-center" v-if="statsLoading">
                  <h4>Summary</h4>
                </b-row>
                <LoadingSpinner :loading="statsLoading" />
                <b-row align-h="between" class="stats-summary">
                  <b-col
                    v-for="(stat, key, index) in stats"
                    v-bind:key="`stats-${key}`"
                    cols="6"
                    lg="4"
                    :class="`row-${index} mb-3`">
                    <b-row align-h="center" class="text-center">
                      <h4>{{ stat.displayName }}</h4>
                    </b-row>
                    <b-row align-h="between">
                      <b-col class="text-center text-secondary stat-value">
                        <b-badge class="badge-secondary badge-outlined" v-if="stat.slug !== 'duration'">
                          {{ numericDisplay(stat.value) }} <span v-if="stat.displayUnit">{{ stat.displayUnit }}</span>
                        </b-badge>
                        <b-badge class="badge-secondary badge-outlined" v-if="stat.slug === 'duration'">
                          {{ timeDisplay(stat.value) }} <span v-if="stat.displayUnit">{{ stat.displayUnit }}</span>
                        </b-badge>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-container>
            </b-card>
          </b-col>
          <b-col
            cols="12"
            lg="6"
            class="panel">
            <PersonalRecords :fitnessDiscipline="fitnessDiscipline"/>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <hr />
    <b-row align-h="center">
      <b-col
        cols="12"
        md="6"
        class="panel">
        <b-card class="h-100">
          <b-container fluid>
            <b-row align-h="center">
              <h4>Metric Charts Options</h4>
            </b-row>
            <LoadingSpinner :loading="statsLoading" />
            <br />
            <b-form class="filters" novalidate>
              <b-row>
                <b-col
                  v-for="(filter, index) in metricFilters"
                  v-bind:key="`filter-${index}`"
                  cols="4">
                  <label :for="index">{{ filter.displayName }}</label>
                  <b-form-select
                    :id="index"
                    v-model="filters[index]"
                    type="select"
                    required
                    :class="index"
                    :options="filter.values"
                    text-field="displayName"
                    value-field="id"
                    @change="updateCharts()"
                  ></b-form-select>
                </b-col>
              </b-row>
            </b-form>
          </b-container>
        </b-card>
      </b-col>
      <b-col
        v-for="(metric, key) of metrics"
        v-bind:key="`metric-${key}`"
        cols="12"
        md="6"
        class="panel">
        <b-card>
          <b-container fluid>
            <b-row align-h="center" v-if="chartsLoading">
              <h4>Metric</h4>
            </b-row>
            <LoadingSpinner :loading="chartsLoading" />
            <b-row align-h="center" v-if="!chartsLoading">
              <h4>{{ metric }}</h4>
            </b-row>
            <b-row v-if="!chartsLoading">
              <div class="highcharts-wrapper">
                <highcharts
                  v-if="charts[key] && charts[key].series[0].data.length"
                  :options="charts[key]"
                  width="100%"
                  ref="highcharts"
                ></highcharts>
                <h5
                  v-if="!charts[key]"
                  class="text-center">
                  No data
                </h5>
              </div>
            </b-row>
          </b-container>
        </b-card>
      </b-col>
    </b-row>
    <hr />
    <b-row>
      <b-col
        cols="12"
        class="panel">
        <b-card>
          <b-row align-h="center">
            <h4>Workouts By Instructor</h4>
          </b-row>
          <LoadingSpinner :loading="instructorsLoading" />
          <br />
          <InstructorStats v-if="!instructorsLoading" :data="workoutsByInstructor" />
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<style lang="scss">
  @import '../../styles/CyclingDashboard';
</style>

<script>
import _orderBy from 'lodash/orderBy'
import axios from 'axios'
import Vue from 'vue'
import InstructorStats from '../modules/InstructorStats'
import LoadingSpinner from '../modules/LoadingSpinner'
import PersonalRecords from '../modules/PersonalRecords'

const HighChartEventBus = new Vue()

export default {
  components: {
    InstructorStats,
    LoadingSpinner,
    PersonalRecords
  },
  data() {
    return {
      charts: {},
      chartOptions: {
        chart: {
          type: 'spline', 
          height: '25%',
          spacingBottom: 10,
        },
        tooltip: {
          headerFormat: '',
          pointFormat: '<b>{point.y} {point.unit}</b><br />{point.className}',
        },
        title: '',
        xAxis: {
          crosshair: true,
          labels: {
            enabled: false,
          },
          grid: {
            enabled: false,
          },
        },
        yAxis: {
          title: '',
        },
        legend: {
          enabled: false,
        },
        series: [],
        plotOptions: {
          series: {
            marker: {
              enabled: false,
            },
            point: {
              events: {
                mouseOver(event) {
                  HighChartEventBus.$emit('highchartPointHover', this, 'over', event)
                },
                mouseOut(event) {
                  HighChartEventBus.$emit('highchartPointHover', this, 'out', event)
                },
              },
            },
          },
        },
      },
      chartsLoading: true,
      instructorsLoading: true,
      filters: {},
      fitnessDiscipline: 'cycling',
      metricFilters: {},
      metrics: {
        output: 'Output',
        cadence: 'Cadence',
        resistance: 'Resistance',
        speed: 'Speed',
        heart_rate: 'Heart Rate',
      },
      minimumClasses: 5,
      records: [],
      recordsLoading: true,
      statsLoading: true,
      metricFiltersLoading: true,
      stats: [],
      workouts: {},
      workoutsByInstructor: {},
    }
  },
  methods: {
    timeDisplay(value) {
      const hours = Math.floor(value / 60 / 60)
      const minutes = ((value / 60) % 60)
      return `${hours}hr ${minutes}min`
    },
    numericDisplay(value) {
      return value.toLocaleString()
    },
    updateCharts() {
      this.chartsLoading = true
      this.$store.dispatch('CyclingDashboard/loadChartData', {
        options: this.chartOptions,
        filters: { ...this.filters },
      }).then((charts) => {
        this.charts = charts
        this.chartsLoading = false
      })
    },
  },
  mounted() {
    HighChartEventBus.$on('highchartPointHover', (activeChart, action, event) => {
      const hoverPoint = event.target
      this.$refs.highcharts.forEach(({ chart }) => {
        if (chart === activeChart) return
        chart.series.forEach((series) => {
          // Do not highlight regression lines
          if (!series.userOptions.regression) {
            series.data.forEach((point) => {
              if (point.x === hoverPoint.x) {
                if (action === 'over') {
                  point.setState('hover')
                  chart.tooltip.refresh(point)
                  chart.xAxis[0].drawCrosshair(event, point)
                } else {
                  point.setState()
                  chart.tooltip.hide()
                  chart.xAxis[0].hideCrosshair()
                }
              }
            })
          }
        })
      })
    })
    this.$store.dispatch('CyclingDashboard/loadMetricFilters', {})
      .then((metricFilters) => {
        this.metricFiltersLoading = false
        this.metricFilters = metricFilters
        // Set up default values
        Object.keys(this.metricFilters).forEach((metricFilter) => {
          if (!this.filters[metricFilter]) {
            this.filters[metricFilter] = this.metricFilters[metricFilter].values[0].id
          }
        })
        this.$store.dispatch('CyclingDashboard/loadChartData', {
          options: this.chartOptions,
          filters: { ...this.filters },
        })
          .then((charts) => {
            this.charts = charts
            this.chartsLoading = false
          })
          .catch((err) => {
            this.loginError = err.message
          })
      })
    this.$store.dispatch('CyclingDashboard/loadStatsData')
      .then((stats) => {
        this.statsLoading = false
        this.stats = stats
      })
      .catch((err) => {
        this.loginError = err.message
      })

    // Get all workouts by instructor
    axios
      .get(`${process.env.VUE_APP_API_HOST}/workouts/instructor-summary?fitnessDiscipline=${this.fitnessDiscipline}&from=0`)
      .then((response) => {
        this.workoutsByInstructor = response.data
        this.workoutsByInstructor = _orderBy(
          this.workoutsByInstructor,
          instructorWorkoutData => instructorWorkoutData.totalWorkouts,
          ['desc'])
      })
      .catch((err) => {
        console.log(err)
        this.errored = true
      })
      .finally(() => {
        this.instructorsLoading = false
      })
  },
  name: 'CyclingDashboard',
}
</script>
