<template>
  <b-card>
    <b-row align-h="center">
      <h4>Achievements</h4>
    </b-row>
    <LoadingSpinner :loading="loading" />
    <b-row class="h-scroll flex-row flex-nowrap mx-0" v-if="!errored && achievements.length">
      <b-col cols="4" md="2" lg="4" class="text-center achievement"
         v-for="(chunk, index) in chunkedAchievements"
         v-bind:key="index">
        <b-row>
          <b-col cols="12" class="achievement"
                 v-for="(achievement) in chunk"
                 v-bind:key="achievement.id">
            <span class="d-inline-block" v-b-tooltip.top :title="achievement.name">
              <b-avatar :src="achievement.imageUrl" class="img-thumbnail" size="5em"></b-avatar>
            </span>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-icon icon="chevron-bar-left"></b-icon>
    <b-icon icon="chevron-bar-right"></b-icon>
  </b-card>
</template>

<style lang="scss">
  @import '../../styles/AchievementsList';
</style>

<script>
import axios from 'axios'
import _chunk from 'lodash/chunk'
import LoadingSpinner from './LoadingSpinner'

export default {
  components: {
    LoadingSpinner,
  },
  computed: {
    chunkedAchievements() {
      return _chunk(Object.values(this.achievements), this.achievementsPerColumn)
    },
  },
  data() {
    return {
      achievements: [],
      achievementsPerColumn: 2,
      errored: '',
      loading: true,
    }
  },
  mounted() {
    axios
      .get(`${process.env.VUE_APP_API_HOST}/peloton/achievements`)
      .then((response) => {
        this.achievements = response.data
      })
      .catch((err) => {
        console.log(err)
        this.errored = true
      })
      .finally(() => {
        this.loading = false
      })
  },
  name: 'AchievementsList',
}
</script>
