<template>
  <b-container fluid>
    <b-row align-h="center">
      <b-col cols="12" lg="6">
        <h1 class="text-center">
        </h1>
      </b-col>
    </b-row>
    <b-row align-h="center" class="text-center">
      <b-col cols="6" lg="4" xl="2">
        <b-form class="login" @submit.prevent="login" novalidate>
          <b-alert v-if="loginError" show variant="danger">{{ loginError }}</b-alert>
          <b-form-input
            id="email"
            v-model="email"
            type="email"
            required
            placeholder="Enter email"
            class="email"
            :class="{ 'is-invalid': submitted && $v.email.$error }"
          ></b-form-input>
          <b-form-input
            id="password"
            v-model="password"
            type="password"
            required
            placeholder="Password"
            class="password"
            :class="{ 'is-invalid': submitted && $v.password.$error }"
          ></b-form-input>
          <b-button type="submit" variant="secondary" class="text-uppercase">log in</b-button>
        </b-form>
      </b-col>
    </b-row>
  </b-container>
</template>

<style scoped lang="scss">
  @import '../styles/TheHome.scss';
</style>

<script>
import { email, required } from 'vuelidate/lib/validators'

export default {
  components: {},
  data() {
    return {
      email: '',
      loginError: false,
      password: '',
      submitted: false,
    }
  },
  methods: {
    login() {
      this.submitted = true

      // stop here if form is invalid
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      // eslint-disable-next-line no-shadow
      const email = this.email
      const password = this.password
      this.loginError = ''
      this.$store.dispatch('User/login', { email, password })
        .then(() => this.$router.push('/dashboard'))
        .catch((err) => {
          this.loginError = err
        })
    },
  },
  name: 'TheHome',
  validations: {
    email: {
      required,
      email,
    },
    password: {
      required,
    },
  },
}
</script>
