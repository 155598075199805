<template>
  <b-card class="h-100">
    <b-row align-h="center">
      <h4>Personal Records</h4>
    </b-row>
    <LoadingSpinner :loading="loading" />
    <b-row class="h-scroll flex-row flex-nowrap mx-0" v-if="!loading">
      <b-col cols="6" lg="3" class="text-center record mt-1 mb-2"
         v-for="(record, key) in records"
         v-bind:key="key">
        {{ record.name }}
        <div
          class="record-value font-weight-bold">
          <b-badge class="badge-tertiary badge-outlined mt-3 mb-3">
            {{ record.value }}<span class="small">{{ record.unit }}</span>
          </b-badge>
        </div>
        {{ recordDate(record.workoutDate) }}
      </b-col>
    </b-row>
    <b-icon icon="chevron-bar-left"></b-icon>
    <b-icon icon="chevron-bar-right"></b-icon>
  </b-card>
</template>


<style lang="scss">
  @import '../../styles/PersonalRecords';
</style>

<script>
import moment from 'moment'
import axios from 'axios'
import LoadingSpinner from './LoadingSpinner'

export default {
  components: {
    LoadingSpinner,
  },
  data() {
    return {
      loading: true,
      records: [],
    }
  },
  methods: {
    recordDate(date) {
      return moment(date).format('l')
    },
  },
  mounted() {
    // Get all records
    axios
      .get(`${process.env.VUE_APP_API_HOST}/peloton/personal-records/${this.fitnessDiscipline}`)
      .then((response) => {
        this.records = response.data.records
      })
      .catch((err) => {
        console.log(err)
        this.errored = true
      })
      .finally(() => {
        this.loading = false
      })
  },
  name: 'PersonalRecords',
  props: {
    fitnessDiscipline: String,
  },
}
</script>
