import Vue from 'vue';
import Router from 'vue-router';
import TheHome from '@/components/TheHome';
// import TheSignup from '@/components/TheSignup';
import TheDashboard from '@/components/TheDashboard';
import CyclingDashboard from '@/components/cycling/CyclingDashboard';
import TheNotFound from '@/components/TheNotFound';
import store from '../store';

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "Home",
      component: TheHome,
      meta: {
        hideForAuth: true,
        title: 'Login | My Peloton Analytics',
      }
    },
    // {
    //   path: '/signup',
    //   name: 'Signup',
    //   component: TheSignup,
    //   meta: {
    //     guest: true,
    //   },
    // },
    {
      path: "/dashboard",
      name: "Dashboard",
      component: TheDashboard,
      meta: {
        requiresAuth: true,
        title: 'Dashboard | My Peloton Analytics',
      }
    },
    {
      path: "/cycling",
      name: "Cycling",
      component: CyclingDashboard,
      meta: {
        requiresAuth: true,
        title: 'Cycling | My Peloton Analytics',
      }
    },
    {
      path: "/404",
      component: TheNotFound,
      meta: {
        title: '404 Not Found | My Peloton Analytics',
      }
    },
    {
      path: "*",
      redirect: '/404'
    }
  ]
});

// Handle redirects to home if not logged in
router.beforeEach((to, from, next) => {
  store.dispatch('User/authenticate')
    .finally(() => {
      // Page requires auth so verify that user is logged in
      if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters['User/isLoggedIn']) {
          if (store.getters['User/user'].pelotonUsername) {
            _handleMetaTags(to, from)
            next();
            return;
          }
          if (to.path !== '/dashboard') {
            _handleMetaTags(to, from)
            next('/dashboard');
            return;
          }
          _handleMetaTags(to, from)
          next();
        }
        _handleMetaTags(to, from)
        next('/');
        // User is logged in but trying to get to homepage
      } else if (to.path === '/' && store.getters['User/isLoggedIn']) {
        _handleMetaTags(to, from)
        next('/dashboard');
      } else {
        _handleMetaTags(to, from)
        next();
      }
    })
    // eslint-disable-next-line no-unused-vars
    .catch((err) => {
      if (!to.matched.some(record => record.meta.requiresAuth)) {
        // Go back to homepage
        next();
      } else {
        next('/');
      }
    });
});

function _handleMetaTags(to, from) {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return;

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
    // Add the meta tags to the document head.
    .forEach(tag => document.head.appendChild(tag));
}

export default router;
