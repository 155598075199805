<template>
  <b-card no-body v-if="!errored">
    <b-card-body class="px-0 mb-1">
      <h4
        class="mt-1 mb-1 text-center">
        {{ calendarHeader }}
      </h4>
      <LoadingSpinner :loading="loading" />
      <vc-calendar
        is-expanded
        nav-visibility="hidden"
        :attributes="calendarProps"
        :input-props="calendarInputProps"
        v-if="!loading"/>
      <h4
        class="mt-1 mb-0 text-center"
        v-if="!loading">Current Streak: <span class="text-secondary">{{ currentStreak }} weeks</span>
      </h4>
    </b-card-body>
  </b-card>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import LoadingSpinner from './LoadingSpinner'

export default {
  data() {
    return {
      workoutsByDate: [],
      errored: '',
      loading: true,
      calendarProps: [
        {
          key: 'today',
          highlight: true,
          dates: new Date(),
        },
      ],
      calendarInputProps: [{
        'read-only': true,
      }],
      currentStreak: '',
    }
  },
  components: {
    LoadingSpinner,
  },
  computed: {
    calendarHeader() {
      return moment().format('MMMM YYYY')
    }
  },
  methods: {},
  mounted() {
    const firstDay = Math.round(moment().subtract(30, 'days'))
    const lastDay = Math.round(moment().endOf('day'))
    axios
      .get(`${process.env.VUE_APP_API_HOST}/workouts?from=${firstDay}&to=${lastDay}`)
      .then((response) => {
        this.workoutsByDate = response.data.workouts
        this.currentStreak = response.data.currentStreak

        // Loop over all workouts and set dots in attributes
        this.workoutsByDate.forEach((workout) => {
          const bars = {
            bar: {
              class: 'completed-workout',
            },
            dates: [new Date(workout.data.startTime * 1000)],
            popover: {
              label: workout.data.ride.title,
              placement: 'auto-end'
            },
          }
          // Date are UNIX Timestamp and in seconds, convert to milli
          this.calendarProps.push(bars)
        })
      })
      .catch((err) => {
        console.log(err)
        this.errored = true
      })
      .finally(() => {
        this.loading = false
      })
  },
  name: 'WorkoutsCalendar',
}
</script>

<style lang="scss">
  @import '../../styles/WorkoutsCalendar';
</style>