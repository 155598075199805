<template>
  <b-card v-if="!errored">
    <b-container fluid>
      <b-row>
        <h4>{{ title }}</h4>
      </b-row>
      <LoadingSpinner :loading="loading" />
      <b-row class="workouts" :class="`${fixedHeight === 'true' ? 'fixedHeight': ''}`">
        <b-table
          :items="workoutsList"
          :fields="fields"
          borderless
          hover
          head-variant="dark"
          class="w-100">
          <template v-slot:cell(info)="data">
            <span class="font-weight-bold">{{ data.value.title }}</span>
            <span class="small"> - {{ data.value.date }}</span>
          </template>
          <template v-slot:cell(instructor)="data">
            <b-avatar
              :src="data.value.image"
              class="instructor"
              v-b-tooltip.hover
              :title="`${data.value.name}`"></b-avatar>
          </template>
        </b-table>
      </b-row>
      <b-row align-h="center">
        <b-icon icon="chevron-bar-down"></b-icon>
      </b-row>
    </b-container>
  </b-card>
</template>

<style scoped lang="scss">
  @import '../../styles/WorkoutsList';
</style>

<script>
import axios from 'axios'
import moment from 'moment'
import LoadingSpinner from './LoadingSpinner'

export default {
  components: {
    LoadingSpinner,
  },
  data() {
    return {
      workoutsList: [],
      fields: [
        {
          key: 'instructor',
          tdClass: 'instructor font-weight-bold',
          thClass: 'd-none',
        },
        {
          key: 'info',
          tdClass: 'align-middle',
          thClass: 'd-none',
        },
        {
          key: 'date',
          tdClass: 'align-middle',
          thClass: 'd-none',
        },
        {
          key: 'output',
          tdClass: 'text-secondary font-weight-bold align-middle',
          thClass: 'd-none',
        },
      ],
      errored: '',
      loading: true,
    }
  },
  methods: {
    getOutput(totalWork) {
      return totalWork > 0 ? `${(totalWork / 1000).toFixed(0)} kj` : ''
    },
  },
  mounted() {
    if (!this.workouts) {
      let queryString = ''
      if (this.from && this.to) {
        queryString = `?from=${this.from}&to=${this.to}`
      }

      axios
        .get(`${process.env.VUE_APP_API_HOST}/workouts${queryString}`)
        .then((response) => {
          this.workoutsList = response.data.workouts.map(workout => ({
            info: {
              title: workout.data.ride.title,
              date: moment.unix(workout.data.startTime).format('l'),
            },
            instructor: {
              name: workout.instructor.data.name,
              image: workout.instructor.data.imageUrl,
            },
            output: this.getOutput(workout.data.totalWork),
          }))
        })
        .catch((err) => {
          console.log(err)
          this.errored = true
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  name: 'WorkoutsList',
  props: {
    title: String,
    fixedHeight: Boolean,
    workouts: Array,
    from: Number,
    to: Number,
  },
}
</script>
