<template>
  <b-card>
    <b-row align-h="center">
      <h4>Classes Taken</h4>
    </b-row>
    <LoadingSpinner :loading="loading" />
    <b-row v-if="!errored && workoutsSummary.length">
      <div class="highcharts-wrapper no-x-axis-grid">
        <highcharts :options="chartOptions" width="100%"></highcharts>
      </div>
    </b-row>
  </b-card>
</template>

<style scoped lang="scss">
  @import '../../styles/WorkoutsSummary';
</style>

<script>
import _orderBy from 'lodash/orderBy'
import axios from 'axios'
import LoadingSpinner from './LoadingSpinner'

export default {
  components: {
    LoadingSpinner,
  },
  data() {
    return {
      chartOptions: {
        chart: {
          type: 'bar',
          height: '75%',
          spacingBottom: 0,
          style: {
            fontFamily: 'roboto',
          },
        },
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: true,
              verticalAlign: 'middle',
            },
          },
        },
        tooltip: {
          enabled: false,
        },
        title: '',
        xAxis: {
          categories: [],
          tickmarkPlacement: 'off',
          lineWidth: 0,
          minorGridLineWidth: 0,
          gridLineWidth: 0,
        },
        yAxis: {
          min: 0,
          title: '',
        },
        series: [],
      },
      workoutsSummary: [],
      errored: '',
      loading: true,
    }
  },
  methods: {
    sortedArray(array, order) {
      return _orderBy(array, ['count'], [order])
    },
  },
  mounted() {
    axios
      .get(`${process.env.VUE_APP_API_HOST}/workouts/summary`)
      .then((response) => {
        this.workoutsSummary = this.sortedArray(response.data, 'desc')

        // Set up the chart
        this.chartOptions.xAxis.categories = this.workoutsSummary.map(workout => workout.name)
        this.chartOptions.series.push({
          data: this.workoutsSummary.map(workout => workout.count),
          showInLegend: false,
        })

        // Set the max to highest number + 20
        this.chartOptions.yAxis.max = this.chartOptions.series[0].data[0] + 20
      })
      .catch((err) => {
        console.log(err)
        this.errored = true
      })
      .finally(() => {
        this.loading = false
      })
  },
  name: 'WorkoutsSummary',
}
</script>
